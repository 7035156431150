<template>
  <div>
    <div class="block-main">
      <div class="header">
        <h1>Компания заблокирована</h1>
      </div>
      <div class="content">
        <div class="content__companyName">
          <span>Компания: </span>
          <span>{{ companyName }} </span>
        </div>
        <div class="content__companyBalance">
          <span>Баланс: </span>
          <span class="content__companyBalance-balance">
            {{ currentBalance }} {{ currency.display_name }}
          </span>
        </div>
        <div class="content__history">
          <span
            @click="isPaymentHistory = true"
            class="content__history-historyBtn"
            >История списаний</span
          >
        </div>
        <div class="content__info">
          <span v-if="billingDomain">
            Доступ к компании заблокирован. Вы можете оплатить задолженность в
            размере <span> {{ amount }} </span> для возобновления доступа или
            перейти в другую компанию.
          </span>
          <span v-else>
            Доступ к компании заблокирован. Обратитесь к интегратору для
            возобновления доступа или перейдите в другую компанию.
          </span>
        </div>

        <BalanceTopUp
          :amount="amountToPay"
          v-if="isBalanceTopUp"
          @onClose="isBalanceTopUp = false"
        />

        <div v-if="billingDomain" @click="payNow" class="content__btn">
          <span>Оплатить {{ amount }}</span>
        </div>
      </div>

    </div>
    <PaymentHistory @close="isPaymentHistory = false" v-if="isPaymentHistory" />
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import BalanceTopUp from '@/components/companyControlsCenter/components/Balance/BalanceTopUpModal.vue';

export default {
  components: {
    BalanceTopUp,
    PaymentHistory: () => import('./PaymentHistory.vue')
  },
  data() {
    return {
      isBalanceTopUp: false,
      companyName: '',
      currentBalance: '',
      currency: '',
      amountToPay: '',
      isPaymentHistory: false,
      isPayementReady: false
    }
  },

  computed: {
    ...mapState('login', {
      me: (state) => state.me
    }),
    ...mapState('billing', {
      order: (state) => state.order,
      transactions: (state) => state.transactions
    }),
    ...mapGetters({
      companyBilling: 'login/companyBilling'
    }),

    billingDomain() {
      return this.companyBilling.domain
    },

    amount() {
      return `${Math.abs(this.amountToPay)} ${this.currency.display_name}`
    }
  },
  watch: {
    order(val) {
      this.isPayementReady = true
    }
  },
  methods: {
    ...mapActions({
      createNewOrder: 'billing/CREATE_NEWORDER',
      createOrder: 'billing/CREATE_ORDER'
    }),
    payNow() {
      this.isBalanceTopUp = true;
    }
  },
  created() {
    this.companyName = this.me.active_company.name
    const { billing } = this.me.active_company
    this.currentBalance = billing.amount
    this.amountToPay =
      billing.amount < 0
        ? billing.amount
        : billing.tariff.total - billing.amount

    this.currency = billing.currency
  }
}
</script>

<style scoped lang="scss">
.block-main {
  background: #ffffff;
  width: 400px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  margin: 16px;
  border-left: 4px solid #df6666;
  border-radius: 4px;
  padding: 20px;
  .header {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
    color: #446c9d;
  }
  .content {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    &__companyName {
      color: #81abe1;
      margin-top: 12px;
    }
    &__companyBalance {
      color: #81abe1;
      &-balance {
        color: #c93131;
      }
    }
    &__history {
      margin-bottom: 16px;
      &-historyBtn {
        cursor: pointer;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 100%;
        color: #446c9d;
      }
    }
    &__info {
      font-weight: 400;
    }
    &__btn {
      width: 100%;
      border-radius: 6px;
      background-color: #446c9d;
      color: #ffffff;
      padding: 8px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;
      cursor: pointer;
    }
  }
}
</style>
